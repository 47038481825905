export const LOCAL_STORAGE_KEYS = {
  LOCATION_DATA: "locationData",
  TOP_NEWS: "TOP_news",
  USER_AVATAR: "user-avatar",
  MESSAGE_ITEMS_PEF: "channel_item_",
  MESSAGE_ITEM_PREF: "",

  // Deprecated keys
  NEW_UPDATE: "new_update",
  ABOUT_BODY: "about_body",
  ABOUT_CHILDREN: "about_children",
  ABOUT_DRINK: "about_drink",
  EDUCATION_LEVEL: "education_level",
  ABOUT_HEIGHT: "about_height",
  ABOUT_MORE_CHILDREN: "about__more-children",
  RELIGION: "religion",
  ABOUT_SMOKE: "about_smoke",
  ABOUT_WEIGHT: "about_weight",
  BIRTHDAY: "birthday",
  BODY_TYPE: "body_type",
  CHAT_RANDOM: "chat_random",
  HAS_CHILDREN: "has_children",
  AGE: "age",
  HEIGHT: "height",
  WEIGHT: "weight",
  BIO: "bio",
  DRINK: "drink",
  JOIN_REASONS: "join_reasons",
  MARITAL_STATUS: "marital_status",
  MORE_CHILDREN: "more_children",
  OCCUPATION: "occupation",
  SMOKE: "smoke",
};

export type LocalStorageKey =
  (typeof LOCAL_STORAGE_KEYS)[keyof typeof LOCAL_STORAGE_KEYS];
