export const CHAT_CHANNEL_TYPE_ALL = 0;
export const CHAT_CHANNEL_TYPE_IS_ACTIVE = 1;
export const CHAT_CHANNEL_TYPE_REQUESTED = 2;

export const CHANNEL_STATUS_DELETED = 3;
export const CHANNEL_STATUS_BANNED = 3;

/**
 * Channel member statuses
 */
export const CHANNEL_MEMBER_BANNED_STATUS = 4;

export const MESSAGE_STATUS_SEND = 1;
export const MESSAGE_STATUS_DELIVERED = 2;

/**
 * Current statuses use on frontend and backend
 */
export const MESSAGE_MEDIA_STATUS_ACTIVE = 1;
export const MESSAGE_MEDIA_STATUS_CONVERT = 2;
export const MESSAGE_MEDIA_STATUS_DELETED = 3;
export const MESSAGE_MEDIA_STATUS_BANNED = 4;
/**
 * Current statuses use only in frontend
 */
export const MESSAGE_MEDIA_STATUS_SEND = 5;
export const MESSAGE_MEDIA_STATUS_UPLOADED = 6;
export const MESSAGE_MEDIA_STATUS_BROKEN = 7;

export const CHAT_MESSAGE_TYPE_TEXT = 1;
export const CHAT_MESSAGE_TYPE_MEDIA = 2;
export const CHAT_MESSAGE_TYPE_WINK = 3;

export const CHAT_MESSAGE_TYPE_GIFT = 5;
export const CHAT_MESSAGE_TYPE_GIFT_REQUEST = 6;
export const CHAT_MESSAGE_TYPE_THANKSGIVING = 7;

export const CHAT_MESSAGE_MEMBER_STATUS_UNREAD = 1;
export const CHAT_MESSAGE_MEMBER_STATUS_READ = 2;
export const CHAT_MESSAGE_MEMBER_STATUS_BAN = 3;
export const CHAT_MESSAGE_MEMBER_STATUS_DELETE = 4;

/**
 * Event list
 */

export const EVENT_NEW_MESSAGES_COUNT = "s_mc_new";
export const EVENT_NEW_MESSAGES = "server_m_new";
export const EVENT_CHANNEL_UPDATE = "server_c_update";
export const EVENT_SUCCESS_AUTH = "s_ua_success";
export const EVENT_USER_LIST_IS_ONLINE = "s_ul_online";
export const EVENT_USER_OFFLINE = "s_u_offline";
export const EVENT_USER_ONLINE = "s_u_online";
export const EVENT_TYPING = "server_m_typ";
export const EVENT_BAN_CHANNEL = "s_c_ban";
export const EVENT_DELETE_CHANNEL = "s_d_channel";
export const EVENT_MESSAGE_UPDATE = "s_m_update";
export const EVENT_MEDIA_PURCHASED = "s_media_purchased";
export const EVENT_DELETE_MESSAGE = "s_m_delete";
export const EVENT_OPEN_GIFT = "s_open_gift";
export const EVENT_ERROR = "error";

export const EMIT_GET_CHANNELS = "get_channels";
export const EMIT_GET_MORE_CHANNELS = "get_more_channels";
export const EMIT_GET_NEWBIE_CHANNELS = "get_newbie_channels";
export const EMIT_GET_UPDATE_CHANNELS = "get_updated_channels";
export const EMIT_GET_ONLINE_USERS = "get_online";
export const EMIT_DELETE_CHANNEL = "delete_channel";
export const EMIT_BLOCK_CHANNEL = "block_channel";
export const EMIT_UN_BLOCK_CHANNEL = "un_block_channel";
export const EMIT_GET_MESSAGES = "get_messages";
export const EMIT_CONSUME_MESSAGES = "consume_messages";
export const EMIT_TYPING_MESSAGE = "typing_message";
export const EMIT_SEND_MESSAGE = "send_message";
export const EMIT_PURCHASE_MEDIA = "purchase_media";

export const EMIT_OPEN_GIFT = "open_gift";

export const EMIT_PIN_CHANNEL = "channel_pin";
export const EMIT_UN_PIN_CHANNEL = "channel_un_pin";
export const EMIT_GET_CHANNEL_WITH_USER = "get_channel";

/**
 * Error codes
 */
export const CHAT_ERROR_NOT_AUTH = 401;
export const CHAT_ERROR_NOT_ENOUGH_CREDITS = 443;
export const CHAT_ERROR_NOT_ENOUGH_CREDITS_WITH_MEDIA = 446;
export const CHAT_ERROR_SERVICE_UNAVAILABLE = 503;
export const CHAT_ERROR_USER_BLOCKED = 453;
export const CHAT_ERROR_CHANNEL_NOT_AVAILABLE = 413;
export const CHAT_ERROR_GET_SELECTED_CHANNEL = 406;

export const CHANNEL_IDENTITY_SEPARATOR = "_";

export const MESSAGE_MIN_SIZE = 1;
export const MESSAGE_MAX_SIZE = 200;

export const FREE_MSG_LIMIT = 3;

export const MESSAGE_TYPING_TIMEOUT = 3000; //3 sec

export const GIFT_STATUS_NEW = 1;
export const GIFT_STATUS_OPENED = 2;

export const GIFT_TYPE_ITEM = 1;
export const GIFT_TYPE_REQUEST = 2;

export const QUICK_MESSAGES = [
  { id: 1, text: "Hi!", icon: "👋" },
  { id: 2, text: "What makes you feel good?", icon: "😄" },
  { id: 3, text: "How is your day?", icon: "☀" },
  { id: 4, text: "What's up, sweetheart?", icon: "😘" },
  { id: 5, text: "Tell me more about you", icon: "😍" },
  { id: 6, text: "What are you looking for?", icon: "👀" },
  { id: 7, text: "Are you happy?", icon: "😊" },
  { id: 8, text: "Is age important?", icon: "🤔" },
  { id: 9, text: "Do you like chocolate?", icon: "🍫" },
  { id: 10, text: "What cheers you up?", icon: "😉" },
];
